* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

:root {
  --main_color: #B00819;
  --gray_color: #4d4d4d;
  --white_color: white;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--gray_color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: var(--white_color);
}

.title {
  text-align: start;
  margin: 0;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.main_container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#header_container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  padding-top: 10px;
  position: sticky;
  top: 0;
  background-color: var(--white_color);
  z-index: 1;
}

#header_container nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1100px;
  width: 100%;
}

#header_container .popupBtn {
  margin: 30px 0;
}

#header_container .right_panel {
  display: flex;
}

#header_container .header_items {
  cursor: pointer;
}

#header_container nav .header_items {
  display: flex;
  margin-right: 80px;
}

#header_container nav .header_item {
  margin: 0 15px;
  margin-top: 35px;
  font-weight: 500;
  text-transform: uppercase;
}

#header_container .header_slider_container .header_item {
  margin: 0 15px;
  margin-top: 35px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--white_color);
}

.burger img {
  width: 40px;
  height: 40px;
  margin: 26px 0;
  cursor: pointer;
  margin-left: 20px;
}

#header_container nav .burger img {
  display: none;
}

.header_slider_container {
  width: 100vw;
  height: 100vh;
  background-color: var(--main_color);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all .2s ease-in-out;
  transform: translateX(100%);
  overscroll-behavior: none;
}

.header_slider_container .burger {
  float: right;
  margin-right: 35px;
}

.header_slider_container .header_items {
  margin: 0 auto;
  width: 50vw;
  text-align: center;
  margin-top: 30vh;
}


.carousel {
  position: relative;
  width: 100%;
  display: flex;
  padding: 0 45px;
  max-width: 1100px;
}

.carousel_space {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  width: 200%;
  height: 500px;
}

.carousel-item {
  flex: 1;
  text-align: center;
  margin: 0 30px;
  padding: 0 35px;
  border-radius: 30px;
  background-color: var(--white_color);
  width: 50vw;
}

.carousel-item div {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}


.carousel-item>.title-block {
  padding: 35px 0;
  display: flex;
  align-items: center;
}

.carousel-item>.title-block>.icon,
.sector_title_block>.icon {
  width: 65px;
  height: 65px;
}

.sector_title_block>.icon {
  margin-right: 15px;
}

.carousel-item>.title-block h3 {
  margin: 0;
  margin-left: 20px;
  color: var(--main_color);
  font-weight: 600;
}

.carousel-item li {
  text-align: start;
  font-size: 1rem;
}

.carousel-item li::marker {
  font-size: .6rem;
}

.photo_container {
  margin-top: 15px;
  width: 60%;
}

.info_container {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}

#services_container {
  width: 100%;
  position: relative;
  padding-bottom: 20px;
  background-color: var(--main_color);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 20px;
  margin-bottom: 40px;
}

#services_container .title {
  color: var(--white_color);
}

#services_container>.title-block {
  width: 100%;
  max-width: 1100px;
}


.carousel-marker {
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  float: left;
  border: 2px solid var(--main_color);
  margin: 0 4px;
  transition: all .3s;
}

.carousel-marker.active {
  transform: scale(1.4);
  background-color: var(--main_color);
}

.carousel-marker_container {
  position: absolute;
  left: 45%;
}

.carousel_btn {
  position: absolute;
  top: 0%;
  height: 100%;
  width: 30px;
  cursor: pointer;
}

.carousel_btn.left {
  left: 0;
  transform: rotate(180deg);
}

.carousel_btn.right {
  right: 0;
}

.person_container {
  display: flex;
  flex-direction: row;
  align-items: start;
}

.carousel_h2 {
  width: 50%;
  display: flex;
  align-items: center;
}

#calculator_container,
#sectors_container,
#footer_container,
#steps_container {
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: center;
}

#calculator_container {
  margin-top: 90px;
  padding-top: 10px;
}

#footer_container {
  background-color: var(--gray_color);
  color: var(--white_color);
}

#calculator_container .calculator_content,
#sectors_container .sectors_content,
#steps_container .steps_content,
#footer_container .footer_content {
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
}

#sectors_container .sectors_content {
  padding-bottom: 40px;
}

#calculator_container .calculator_content,
#footer_container .footer_content {
  padding-bottom: 150px;
}

#footer_container .footer_content {
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding-top: 90px;
}

.footer_content .left_panel {
  display: flex;
}

.footer_content .title {
  margin-bottom: 40px;
}

.footer_content .company_col,
.footer_content .contacts_col {
  width: 20vw;
}

.footer_content .right_panel {
  width: 25%;
  padding-top: 55px;
}

.footer_content .column_items>* {
  margin: 20px 0;
  cursor: pointer;
}

.footer_content .icon {
  position: absolute;
  top: 15px;
}

.calculator_content>.title-block,
.sectors_content>.title-block,
.steps_content>.title-block {
  width: 100%;
}

.calculator_content>.title-block .title,
.sectors_content>.title-block .title,
.steps_content>.title-block .title {
  color: var(--main_color);
  margin-bottom: 25px;
}

.calculator_content>.title-block h4 {
  margin: 0;
  font-weight: 500;
  margin-bottom: 30px;
}

.calc_button {
  width: 50%;
  height: 40px;
  margin: 20px auto;
  cursor: pointer;
}

.sliders_container {
  display: flex;
  justify-content: space-evenly;
}

.slider-container {
  padding-top: 20px;
}

.slider-container label {
  font-weight: 300;
}

.selects_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}

.select_container {
  width: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 10px 0;
}

.select_container select,
.select_container label {
  width: 100%;
  display: block;
}

.select_container select {
  height: 30px;
  font-size: 15px;
  margin-top: 10px;
}

.select_container label {
  font-size: 15px;
}

.range-body {
  position: relative;
  width: 30vw;
  height: 40px;
  user-select: none;
  cursor: pointer;
  margin-top: 25px;
}

.range-body::after {
  content: '';
  background-color: black;
  width: 101%;
  height: 1px;
  position: absolute;
  top: 50%;
  z-index: -1;
}

.range-outer {
  height: 4px;
  background-color: #cb4b37;
  position: absolute;
  top: 46%;
}

.range-selector {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: var(--main_color);
  border-radius: 50%;
  transform-origin: center center;
  cursor: pointer;
  left: 0%;
  top: 25%;
  transform: translateX(-25%);
  cursor: move;
}

.range-selector .tooltip {
  width: 50px;
  height: 30px;
  position: absolute;
  left: -80%;
  bottom: 120%;
  border-radius: 10px;
  opacity: 0%;
  transition: all .3s;
  text-align: center;
  box-shadow: 1px 5px 10px gray;
}

.slider-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.slider-line span {
  position: absolute;
  top: 25px;
  /* font-size: 10px; */
  left: 50%;
}

.slider-line span::before {
  content: '';
  width: 1px;
  height: 10px;
  position: absolute;
  background-color: black;
  left: 50%;
  bottom: 14px;
  z-index: -1;
}

.result_card_container {
  width: 100%;
}

.result_card {
  background-color: var(--main_color);
  color: var(--white_color);
  font-weight: 400;
  border-radius: 15px;
  height: 70px;
  width: 55%;
  text-align: center;
  padding: 5px;
  padding-top: 25px;
  position: relative;
  float: right;
  margin-bottom: 50px;
}

.admin_container {
  padding: 15px;
}

.admin_container .tabs {
  display: flex;
  margin-top: 15px;
}

.admin_container .tab {
  width: 30vw;
  text-align: center;
  background-color: #d9d9d9;
  height: 40px;
  font-weight: 500;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  cursor: pointer;
}

.admin_container .tab.active {
  background-color: var(--main_color);
  color: var(--white_color);
}

.commits_container,
.screens_container {
  height: 60vh;
  overflow-y: auto;
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid black;
}

.commit {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
}

.commit h3 {
  width: 30vw;
}

.commit span.filename {
  width: 50vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  direction: rtl;
}

.commit span.additions {
  color: green;
}

.commit span.deletions {
  color: #cb4b37;
}

.screens_container {
  padding-top: 20px;
}

.screens_container .stat {
  display: flex;
  justify-content: space-between;
}

.screens_container h3 {
  margin: 10px 0;
  margin-left: 10px;
}

.screens_container .progress_bar {
  width: 50vw;
  height: 40px;
  background-color: #d9d9d9;
  border: 1px solid rgb(82, 82, 82);
  position: relative;
}

.screens_container .progress {
  background-color: var(--main_color);
  height: 100%;
  color: var(--white_color);
}

.screens_container p {
  margin: 0;
  padding-left: 50%;
  position: absolute;
  top: 9px;
  left: 0;
}

#about_us {
  height: calc(100vh - 92px);
  min-height: 400px;
}

#about_us .buttons_block {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
}

.popupBtn,
.calculator_button {
  border-radius: 30px;
  background-color: var(--main_color);
  color: azure;
  height: 2rem;
  width: 10rem;
  font-size: .8rem;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
}

.popupBtn:disabled {
  background-color: var(--gray_color);
  opacity: .3;
}

.calculator_button {
  background-color: var(--gray_color);
}

.about_us_container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 10px;
}

.about_us_block {
  margin-top: 15vh;
  display: flex;
  align-items: center;
}

.about_us_content {
  width: 55%;
  margin-right: 20px;
}

.about_us_content h2,
.about_us_content h3 {
  font-weight: 500;
}

.about_us_content h2 {
  font-size: 30px;
}

.about_us_content h3 {
  text-align: end;
  font-size: 27px;
}

.about_us_block img {
  width: 45%;
}

.sectors {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.sector_block {
  margin: 15px 10px;
  padding: 40px 30px;
  width: 31%;
  border-radius: 30px;
  box-shadow: 0px 2px 3px 0px rgb(188, 188, 188);
  height: 300px;
}

.sector_title_block {
  display: flex;
  align-items: center;
}

.sector_title_block .sector_title {
  font-weight: 600;
  color: var(--main_color);
  word-break: break-word;
  width: 80%;
}

.sector_block .sector_description {
  margin-top: 30px;
}

.half_circle {
  width: 100%;
}

.steps .step {
  width: 20%;
}

.steps {
  display: flex;
  justify-content: space-evenly;
}

.steps .step:nth-of-type(2) {
  margin-top: 75px;
}

.steps .step:nth-of-type(3) {
  margin-top: 150px;
}

.steps .figure {
  position: relative;
}

.steps .ellipse {
  position: absolute;
  width: 87%;
  top: 8%;
  left: -10%;
  aspect-ratio: 1 / 1;
  background-color: var(--main_color);
  opacity: .2;
  border-radius: 50%;
}

.steps .label {
  text-align: center;
  position: absolute;
  left: 35%;
  top: 35%;
  font-weight: 500;
  font-size: 1.5vw;
}

.steps .step_description {
  width: 125%;
  font-size: 20px;
  margin-top: 40px;
  word-break: break-word;
}

.step_description a {
  color: var(--gray_color);
  text-decoration: underline;
  cursor: pointer;
}

.popup {
  padding: 40px;
  width: 100%;
}

.popup input,
.popup select {
  font-size: 15px;
  padding: 2px;
}

.popup .title {
  color: var(--main_color);
  display: flex;
  flex-direction: column;
}

.popup .label {
  font-weight: 500;
  margin-bottom: 5px;
}

.popup form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup .row {
  margin-bottom: 30px;
  width: 100%;
}

.popup .row.contacts,
.popup .ranges {
  display: flex;
}

.popup .row.contacts input {
  width: 100%;
  ;
}

.popup .inline-block {
  display: inline-block;
  flex: 1 1 0;
}

.popup .ranges input {
  flex: 1 1 0;
}

.popup .inline-block:nth-of-type(1),
.popup .ranges input:nth-of-type(1) {
  margin-right: 10px;
}

.popup .inline-block:nth-of-type(2),
.popup .ranges input:nth-of-type(2) {
  margin-left: 10px;
}


.popup .row.call_range .label {
  text-align: center;
}

.popup .popupBtn {
  margin: 20px 0;
}

.popup select {
  width: 100%;
}

@media (max-width: 1250px) {

  .select_container,
  .range-body {
    width: 40vw;
  }
}

@media (max-width: 1100px) {
  #root {
    display: block;
  }

  .carousel-item li {
    font-size: 1.4vw;
  }

  #header_container nav .header_items {
    margin-right: 40px;
  }

  .range-body {
    width: 40vw;
  }

  .select_container {
    width: 40vw;
  }

  .steps .label {
    font-size: 2vw;
  }
}

@media (max-width: 900px) {
  .info_container {
    flex-direction: column;
  }

  .carousel-inner {
    width: 400%;
  }

  .carousel_h2 {
    width: 100%;
  }

  .photo_container {
    width: 80%;
  }

  .person_container {
    flex-direction: column;
  }

  .carousel-item li {
    font-size: 2vw;
  }

  #header_container nav .header_items {
    display: none;
  }

  #header_container nav .burger img {
    display: block;
  }

  .result_card {
    width: 100%;
  }

  .sector_block {
    width: 45%;
  }

  .steps {
    flex-direction: column;
  }

  .steps .step:nth-of-type(1),
  .steps .step:nth-of-type(2),
  .steps .step:nth-of-type(3) {
    margin: 20px 0;
  }

  .steps .step {
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
  }

  .steps .step .figure {
    margin: 10px;
  }

  .steps .step_description {
    font-size: 15px;
    margin: 0;
  }

}

@media (max-width: 700px) {
  .about_us_block img {
    display: none;
  }

  .about_us_content {
    width: 100%;
    margin-right: 0;
  }

  .footer_content .left_panel {
    flex-direction: column;
  }

  .footer_content .company_col,
  .footer_content .contacts_col {
    width: 40vw;
  }

  .footer_content .company_col {
    margin-bottom: 60px;
  }

  .footer_content .right_panel {
    width: 40vw;
  }

  .popup {
    padding: 20px;
  }

  .popup .row.contacts {
    flex-direction: column;
  }

  .popup .inline-block:nth-of-type(1) {
    margin: 0;
  }

  .popup .inline-block:nth-of-type(2) {
    margin: 0;
  }

  .popup .ranges input {
    width: 50%;
  }

  .steps .label {
    font-size: 3vw;
  }
}

@media (max-width: 600px) {
  .carousel-item li {
    font-size: 2.5vw;
  }

  .select_container {
    width: 90vw;
  }

  .sliders_container {
    flex-direction: column;
  }

  .range-body {
    width: 95%;
  }

  .carousel-item {
    margin: 0 10px;
    padding: 0 15px;
  }

  .sector_block {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .carousel-item li {
    font-size: 3vw;
  }

  .result_card {
    padding: 5px;
    padding-top: 15px;
  }

  .carousel-item {
    margin: 0;
    padding: 0 5px;
    border-radius: 15px;
  }

  .carousel-item .icon {
    display: none;
  }

  .carousel-item>.title-block {
    justify-content: center;
  }

  .carousel-item ul {
    padding-inline-start: 20px;
  }

  .carousel-item>.title-block h3 {
    margin: 0;
  }

  #footer_container .footer_content {
    flex-direction: column;
    align-items: center;
  }

  .footer_content .company_col,
  .footer_content .contacts_col,
  .footer_content .right_panel {
    width: 100%;
  }

  .footer_content .right_panel {
    padding-top: 80px;
  }

  .footer_content .right_panel .icon {
    width: fit-content;
    margin: 0 auto;
    position: relative;
  }

  .footer_content .right_panel .description {
    text-align: center;
    margin-top: 30px;
  }

  .steps .step_description {
    width: 100%;
  }

  .steps .label {
    font-size: 4vw;
  }
}